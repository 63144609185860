import { render } from 'react-dom'
import * as ReactDOM from 'react-dom'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { App } from './App'

function renderModalImport(elem: HTMLElement, venueId: string, mediaUrl: string) {
  return render(<App onClose={() => ReactDOM.unmountComponentAtNode(elem)} venueId={venueId} mediaUrl={mediaUrl} />, elem)
}

svrExport('ModalImport', 'render', renderModalImport)
